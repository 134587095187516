import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom';
import { client } from '../../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs'

import styles from './Above.module.scss';

import { ReactComponent as Scroll } from '../../../assets/svg/scroll.svg';

const Above = ({location}) => {
    const [prismicData, setPrismicData] = useState({above: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const above = await client.query([
                    Prismic.Predicates.at('document.type', 'above_the_fold'),
                    Prismic.Predicates.at('my.above_the_fold.pagina', location)
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({above: above.results[0].data});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, [location]);

    const aboveReady = prismicData.above;

    const autoScroll = (e) =>{
        e.target.closest(`.${styles.above}`).nextSibling.scrollIntoView()
    }

    const lastWord = (text) => {
        var splitText = text.trim().split(" ");
        var last = splitText.pop();
        return splitText.join(" ") + (splitText.length > 0 ? " <em>" + last + "</em>" : last);
    };

    return(
        <React.Fragment>
            {
                aboveReady ?
                    aboveReady.cor_de_fundo !== null ?
                    (
                        <div className={styles.above} style={{backgroundColor: aboveReady.cor_de_fundo}}>
                            {
                                aboveReady.imagem.url ?
                                (
                                    <React.Fragment>
                                        <div>
                                            {
                                                aboveReady.pagina === 'Homepage' ?
                                                <h1 style={{fontSize: "11rem", maxWidth: "72rem", marginTop: "10rem"}}>{aboveReady.titulo[0].text}</h1>
                                                : <h1>{aboveReady.titulo[0].text}</h1>
                                            }
                                        </div>
                                        <div>
                                            <img src={aboveReady.imagem.url} alt={aboveReady.imagem.alt} />
                                            {
                                                aboveReady.pagina === 'Homepage' ?
                                                <div className={styles.changeMobile} style={{position: "absolute", bottom: "12rem"}}>
                                                    <RichText render={aboveReady.subtitulo} />
                                                </div>
                                                :
                                                <div>
                                                    <RichText render={aboveReady.subtitulo} />
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                ) : 
                                (
                                    <React.Fragment>
                                        <div>
                                            <h1 style={{maxWidth: 'unset', position: 'absolute'}}>{aboveReady.titulo[0].text}</h1>
                                        </div>
                                        <div className={styles.bottom}>
                                            <RichText render={aboveReady.subtitulo} />
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            <div className={styles.scroll} onClick={autoScroll} ><Scroll /></div>
                        </div>
                    ) :
                    (
                        <div className={styles.above} style={{background: `linear-gradient(rgba(31,32,35,0.4),rgba(31,32,35,0.4)), url(${aboveReady.imagem_fundo.url})`}}>
                            <div>
                                {
                                    aboveReady.pagina === 'Suporte' ?
                                    <h1 style={location === 'Solução' ? {maxWidth: '115rem', position: 'absolute', top: '-20rem'} : null} dangerouslySetInnerHTML={{__html: lastWord(aboveReady.titulo[0].text)}}></h1>
                                    : <h1 style={location === 'Solução' ? {maxWidth: '115rem', position: 'absolute'} : null}>{aboveReady.titulo[0].text}</h1>
                                }
                            </div>
                            <div className={styles.bottom}>
                                <RichText render={aboveReady.subtitulo} />
                            </div>
                            <div className={styles.scroll} onClick={autoScroll} ><Scroll /></div>
                        </div>
                    ) 
                : null
            }
        </React.Fragment>
    );
}

export default Above;