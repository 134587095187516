import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from './FAQS.module.scss'

import Above from '../../components/UI/Above/Above';
import SingleFaq from '../../components/UI/SingleFaq/SingleFaq';

gsap.registerPlugin(ScrollTrigger);

const FAQS = () => {
    const [prismicData, setPrismicData] = useState({faqs: null, faqsPage: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const faqs = await client.query([
                    Prismic.Predicates.at('document.type', 'faqs')
                ], 
                { orderings : '[document.first_publication_date]' });

                const faqsPage = await client.query([
                    Prismic.Predicates.at('document.type', 'pagina_faqs')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({faqs: faqs.results, faqsPage: faqsPage.results[0].data});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const faqsReady = prismicData.faqs;
    const faqsPageReady = prismicData.faqsPage;

    return(
        <React.Fragment>
            {
                faqsReady ?
                (
                    <React.Fragment>
                        <Above location="FAQs" />
                        <section className={styles.faqs}>
                            {
                                faqsReady.map((singleFaq) => <SingleFaq question={singleFaq.data.pergunta[0].text} answer={singleFaq.data.resposta} />)
                            }
                        </section>
                        {
                            faqsPageReady ?
                            <div className={styles.lastImage}>
                                <img src={faqsPageReady.ultima_imagem.url} alt={faqsPageReady.ultima_imagem.alt} />
                            </div>
                            : null
                        }
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    );
}

export default FAQS;