import React from 'react';
import { Link } from 'react-router-dom'

import styles from './Button.module.scss';

import { ReactComponent as Arrow } from '../../../assets/svg/arrow.svg';
import { ReactComponent as Scroll } from '../../../assets/svg/scroll.svg';

const Button = ({text, link, special, out}) => {
    return (
        <React.Fragment>
        {
            !out ?
            <Link to={link} className={`${styles.button} ${special ? styles.special : null}`}>{text} {special ? <Scroll /> : <Arrow />}</Link>
            : <a href={link} target="_blank" rel="noreferrer" className={`${styles.button} ${special ? styles.special : null}`}>{text} {special ? <Scroll /> : <Arrow />}</a>

        }
        </React.Fragment>
    );
}

export default Button;