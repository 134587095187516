import React from 'react';

import styles from './Marquee.module.scss';

const Marquee = ({blue, dark}) => {

    return (
        <div className={styles.marquee}>
            <div className={styles.marqueeInner} aria-hidden="true">
                <span>{dark}</span>
                <span className={styles.blue}>{blue}</span>
                <span>{dark}</span>
                <span className={styles.blue}>{blue}</span>
                <span>{dark}</span>
            </div>
        </div>
    );
}

export default Marquee;