import React from 'react'
import {Switch, Route, BrowserRouter} from 'react-router-dom';

import './index.scss';

import Header from './components/Navigation/Header/Header';
import Footer from './components/Navigation/Footer/Footer';

import Support from './containers/Support/Support';
import FAQS from './containers/FAQS/FAQS';
import Solution from './containers/Solution/Solution';
import About from './containers/About/About';
import Privacy from './containers/Privacy/Privacy';
import Terms from './containers/Privacy/Terms';
import Homepage from './containers/Homepage/Homepage';
import Cursor from './components/UI/Cursor/Cursor'

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Switch>
                <Route exact path="/contactos">
                    <Support />
                </Route>
                <Route exact path="/faqs">
                    <FAQS />
                </Route>
                <Route exact path="/solucao">
                    <Solution />
                </Route>
                <Route exact path="/sobre">
                    <About />
                </Route>
                <Route exact path="/politica-privacidade">
                    <Privacy />
                </Route>
                <Route exact path="/termos-servico">
                    <Terms />
                </Route>
                <Route exact path="/">
                    <Homepage />
                </Route>
            </Switch>
            <Cursor />
            <Footer />
        </BrowserRouter>
    );
}

export default App;
