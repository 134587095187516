import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs'
import { gsap, Cubic } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from './Solution.module.scss'

import Above from '../../components/UI/Above/Above';
import Button from '../../components/UI/Button/Button';
import Aplicability from '../../components/UI/Aplicability/Aplicability';
import Marquee from '../../components/UI/Marquee/Marquee';
import Advantage from '../../components/UI/Advantage/Advantage';
import Work from '../../components/UI/Work/Work';

import { ReactComponent as Next } from '../../assets/svg/next.svg';

import folha1 from '../../assets/img/folha1.png';
import folha2 from '../../assets/img/folha2.png';
import folha3 from '../../assets/img/folha3.png';
import folha4 from '../../assets/img/folha4.png';
import folha5 from '../../assets/img/folha5.png';

gsap.registerPlugin(ScrollTrigger);

const Solution = () => {
    const [prismicData, setPrismicData] = useState({solution: null, aplicability: null, advantages: null, works: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const aplicability = await client.query([
                    Prismic.Predicates.at('document.type', 'aplicabilidade')
                ], 
                { orderings : '[document.first_publication_date]' });

                const advantages = await client.query([
                    Prismic.Predicates.at('document.type', 'vantagens')
                ], 
                { orderings : '[document.first_publication_date]' });

                const works = await client.query([
                    Prismic.Predicates.at('document.type', 'funcionamento')
                ], 
                { orderings : '[my.funcionamento.ordem]' });

                const solution = await client.query([
                    Prismic.Predicates.at('document.type', 'solucao')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({aplicability: aplicability.results, solution: solution.results[0].data, advantages: advantages.results, works: works.results});
                setTimeout(() => {
                    gsap.to( `.${styles.sheetsImageHolder}`,
                        {
                            duration: .3,
                            opacity: 1,
                            autoAlpha: 1
                        }
                    ); 
                    setTimeout(() => {
                        gsap.to( `.${styles.sheetsImageHolder} img`,
                            {
                                scrollTrigger: {
                                    trigger: `.${styles.sheetsImageHolder}`,
                                    start: "top-=300 top",
                                    once: true
                                },
                                duration: .5,
                                x: 350,
                                stagger: .15,
                                ease: Cubic.easeIn
                            }
                        );   
                    }, 1600);
                }, 400);
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const lastWord = (text) => {
        var splitText = text.trim().split(" ");
        var last = splitText.pop();
        return splitText.join(" ") + (splitText.length > 0 ? " <span>" + last + "</span>" : last);
    };

    let indexSelected = 0;

    const moveBack = (e) =>{
        let els = document.querySelectorAll(`.${styles.worksHolder} > div`);
        let rem = window.innerWidth <= 480 ? 4 : 12;
        indexSelected--;
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = `translate(calc(-${(indexSelected) * 100}vw + ${(indexSelected) * rem}vw))`
            } else{
                el.style.transform = 'translate(0)'
            }
        });
        if(indexSelected === 0) document.querySelector(`.${styles.prev}`).classList.remove(styles.show)
        document.querySelector(`.${styles.next}`).classList.remove(styles.hide)
    }

    const moveForward = (e) =>{
        let els = document.querySelectorAll(`.${styles.worksHolder} > div`);
        console.log(window.innerWidth)
        let rem = window.innerWidth <= 480 ? 12 : 12;
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = `translate(calc(-${(indexSelected+1) * 100}vw + ${(indexSelected+1) * rem}vw))`
            } else{
                el.style.transform = 'translate(calc(-100vw + ' + rem + 'vw))'
            }
        });
        indexSelected++;
        if(indexSelected + 1 === els.length) document.querySelector(`.${styles.next}`).classList.add(styles.hide)
        document.querySelector(`.${styles.prev}`).classList.add(styles.show)
    }

    const solutionReady = prismicData.solution;
    const aplicabilityReady = prismicData.aplicability;
    const advantagesReady = prismicData.advantages;
    const worksReady = prismicData.works;

    return(
        <React.Fragment>
            {
                solutionReady ?
                (
                    <React.Fragment>
                        <Above location="Solução" />
                        <section className={styles.solution}>
                            <div>
                                <RichText render={solutionReady.titulo_solucao} />
                            </div>
                        </section>
                        <section className={styles.works}>
                            <h3>{solutionReady.titulo_como_funciona[0].text}</h3>
                            <div className={styles.worksHolder}>
                                {
                                    worksReady ?
                                    worksReady.map(({data}) => <Work image={data.imagem} subtitle={data.subtitulo[0].text} title={data.titulo[0].text} description={data.descricao} items={data.topicos} />)
                                    : null
                                }
                            </div>
                            <div className={styles.prev} onClick={moveBack}><Next /></div>
                            <div className={styles.next} onClick={moveForward}><Next /></div>
                        </section>
                        <section className={styles.advantages}>
                            <h3>{solutionReady.titulo_vantagens[0].text}</h3>
                            <div>
                                {
                                    advantagesReady ?
                                    advantagesReady.map((advantage) => <Advantage image={advantage.data.icon} title={advantage.data.titulo[0].text} description={advantage.data.descricao[0].text} />)
                                    : null
                                }
                            </div>
                        </section>
                        <div className={styles.solutionMarquee}>
                            <Marquee blue={solutionReady.marquee_azul[0].text} dark={solutionReady.marquee[0].text} />
                        </div>
                        <div className={styles.higlightImage}>
                            <img src={solutionReady.imagem_destaque.url} alt={solutionReady.imagem_destaque.alt} />
                        </div>
                        <section className={styles.aplicability}>
                            <h3>{solutionReady.titulo_aplicabilidade[0].text}</h3>
                            {
                                aplicabilityReady ? aplicabilityReady.map((aplicability) => <Aplicability question={aplicability.data.titulo[0].text} answer={aplicability.data.topicos} />) : null
                            }
                        </section>
                        <section className={styles.form}>
                            <div className={styles.sheetsImageHolder}>
                                <img src={folha1} alt="folha 1" />
                                <img src={folha2} alt="folha 2" />
                                <img src={folha3} alt="folha 3" />
                                <img src={folha4} alt="folha 4" />
                                <img src={folha5} alt="folha 5" />
                            </div>
                            <h4 dangerouslySetInnerHTML={{__html: lastWord(solutionReady.titulo_formulario[0].text)}}></h4>
                            <Button text="Preencha este formulário" special link="/contactos" />
                        </section>
                        <div className={styles.lastImage}>
                            <img src={solutionReady.ultima_imagem.url} alt={solutionReady.ultima_imagem.alt} />
                        </div>
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    );
}

export default Solution;