import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs'
import { gsap, Cubic } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from './Homepage.module.scss';

import Above from '../../components/UI/Above/Above';
import Button from '../../components/UI/Button/Button';
import Marquee from '../../components/UI/Marquee/Marquee';

import folha1 from '../../assets/img/folha1.png';
import folha2 from '../../assets/img/folha2.png';
import folha3 from '../../assets/img/folha3.png';
import folha4 from '../../assets/img/folha4.png';
import folha5 from '../../assets/img/folha5.png';

gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
    const [prismicData, setPrismicData] = useState({homepage: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const homepage = await client.query([
                    Prismic.Predicates.at('document.type', 'homepage')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({homepage: homepage.results[0].data});

                gsap.to( `.${styles.sheetsImageHolder}`,
                    {
                        duration: .3,
                        opacity: 1,
                        autoAlpha: 1
                    }
                );
                gsap.to( `.${styles.sheetsImageHolder2}`,
                    {
                        duration: .3,
                        opacity: 1,
                        autoAlpha: 1
                    }
                );

                setTimeout(() => {
                    const allImg = document.querySelectorAll(`.${styles.sheetsImageHolder} img`);
                    allImg.forEach((el) => {
                        gsap.to(el, {
                            duration: .8,
                            x: 150,
                            stagger: .15,
                            scrollTrigger: {
                                trigger: el,
                                start: 'top-=275 top',
                                scrub: true
                            }
                        });
                    })

                    const allImg2 = document.querySelectorAll(`.${styles.sheetsImageHolder2} img`);
                    allImg2.forEach((el) => {
                        gsap.to(el, {
                            duration: .8,
                            x: 150,
                            stagger: .15,
                            scrollTrigger: {
                                trigger: el,
                                start: 'top-=275 top',
                                scrub: true
                            }
                        });
                    })
                }, 300);
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const homepageReady = prismicData.homepage;

    const lastWord = (text) => {
        var splitText = text.trim().split(" ");
        var last = splitText.pop();
        return splitText.join(" ") + (splitText.length > 0 ? " <span>" + last + "</span>" : last);
    };

    return(
        <React.Fragment>
            {
                homepageReady ?
                (
                    <React.Fragment>
                        <Above location="Homepage" />
                        <div className={styles.sheetsImageHolder}>
                            <img src={folha1} alt="folha 1" />
                            <img src={folha2} alt="folha 2" />
                            <img src={folha3} alt="folha 3" />
                            <img src={folha4} alt="folha 4" />
                            <img src={folha5} alt="folha 5" />
                        </div>
                        <section className={styles.about}>
                            <div>
                                <img src={homepageReady.imagem_sobre.url} alt={homepageReady.imagem_sobre.alt} />
                            </div>
                            <div>
                                {/* <h3>{homepageReady.titulo_sobre[0].text}</h3> */}
                                {
                                    homepageReady.topicos_sobre.map((topic) => <h2>{topic.text}</h2>)
                                }
                                <Button text="Saber mais" link="/contactos" />
                            </div>
                        </section>
                        <section className={styles.videoSection}>
                            <video width="720" height="405" controls poster={homepageReady.imagem_rodape.url}>
                                <source src={homepageReady.video.url} type="video/mp4" />
                                Este browser não suporta o vídeo
                            </video>
                        </section>
                        <section className={styles.isTime}>
                            <div>
                                <h3>{homepageReady.subtitulo_vamos_falar[0].text}</h3>
                                <h2>{homepageReady.titulo_vamos_falar[0].text}</h2>
                                <img src={homepageReady.imagem_vamos_falar.url} alt={homepageReady.imagem_vamos_falar.alt} />
                                <p>{homepageReady.descricao_vamos_falar[0].text}</p>
                                <Button text={homepageReady.texto_solicite_demonstracao[0].text} link="mailto:iarrulo@xevolution.pt" out />
                            </div>
                        </section>
                        <Marquee blue={homepageReady.texto_azul_marquee[0].text} dark={homepageReady.titulo_marquee[0].text} />
                        <div className={styles.higlightImage}>
                            <img src={homepageReady.imagem_destaque.url} alt={homepageReady.imagem_destaque.alt} />
                        </div>
                        <section className={styles.solution}>
                            <div>
                                <h3>{homepageReady.subtitulo_solucao[0].text}</h3>
                                <RichText render={homepageReady.titulo_solucao} />
                                <div className={styles.sheetsImageHolder2}>
                                    <img src={folha1} alt="folha 1" />
                                    <img src={folha2} alt="folha 2" />
                                    <img src={folha3} alt="folha 3" />
                                    <img src={folha4} alt="folha 4" />
                                    <img src={folha5} alt="folha 5" />
                                </div>
                                {/* <img src={homepageReady.imagem_lateral.url} alt={homepageReady.imagem_lateral.alt} /> */}
                            </div>
                        </section>
                        <section className={styles.talk}>
                            <h2 dangerouslySetInnerHTML={{__html: lastWord(homepageReady.titulo_contacto[0].text)}}></h2>
                            <Button text="Fale connosco" special link="/contactos" />
                            <img src={homepageReady.imagem_rodape.url} alt={homepageReady.imagem_rodape.alt} />
                        </section>
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    );
}

export default Homepage;