import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { send } from 'emailjs-com';

import styles from './Support.module.scss'

import Above from '../../components/UI/Above/Above';
import Button from '../../components/UI/Button/Button';
// import {ReactComponent as WhatsApp} from '../../assets/svg/WhatsApp.svg';
import {ReactComponent as Mobile} from '../../assets/svg/mobile.svg';
import {ReactComponent as Phone} from '../../assets/svg/phone.svg';

gsap.registerPlugin(ScrollTrigger);

const Support = () => {
    const [prismicData, setPrismicData] = useState({support: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const support = await client.query([
                    Prismic.Predicates.at('document.type', 'suporte')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({support: support.results[0].data});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const supportReady = prismicData.support;

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        const posFeedback = document.querySelector(`.${styles.positiveFeedback}`);
        const negFeedback = document.querySelector(`.${styles.negativeFeedback}`);
        const fieldsFeedback = document.querySelector(`.${styles.fieldsFeedback}`);
        const nameInput = document.querySelector('input[name="name"]');
        const companyInput = document.querySelector('input[name="company"]');
        const emailInput = document.querySelector('input[name="email"]');
        const telInput = document.querySelector('input[name="tel"]');
        const roleInput = document.querySelector('input[name="role"]');
        const localInput = document.querySelector('input[name="local"]');
        const sectorInput = document.querySelector('input[name="activitySector"]:checked');
        const sizeInput = document.querySelector('input[name="size"]:checked');
        const otherInput = document.querySelector('input[name="other"]');
        const obsInput = document.querySelector('textarea[name="obs"]');

        posFeedback.classList.remove(styles.show);
        negFeedback.classList.remove(styles.show);
        fieldsFeedback.classList.remove(styles.show);

        if(nameInput.value && companyInput.value && emailInput.value){
            send(
                'service_53bi4co',
                'template_halrd59',
                {
                    nome: nameInput.value ? nameInput.value : '',
                    empresa: companyInput.value ? companyInput.value : '',
                    email: emailInput.value ? emailInput.value : '',
                    tel: telInput.value ? telInput.value : '',
                    funcao: roleInput.value ? roleInput.value : '',
                    localidade: localInput.value ? localInput.value : '',
                    setor: sectorInput !== null && sectorInput.value ? sectorInput.value : '',
                    colaboradores: sizeInput !== null && sizeInput.value ? sizeInput.value : '',
                    outroSetor: otherInput.value ? otherInput.value : '',
                    obs: obsInput.value ? obsInput.value : '',
                    subject: 'Nova Lead X-evolution'
                },
                'user_j3T9DNb1qMwcLLoqfi1BC'
            )
            .then((response) => {
                posFeedback.classList.add(styles.show);
                document.querySelector("form").reset();
                window.scrollTo({
                    top: posFeedback.offsetTop - 300,
                    behavior: 'smooth'
                });
                setTimeout(() => {
                    posFeedback.classList.remove(styles.show);
                }, 4000);

                nameInput.classList.remove(styles.error)
                nameInput.nextElementSibling.classList.remove(styles.show)
                companyInput.classList.remove(styles.error)
                companyInput.nextElementSibling.classList.remove(styles.show)
                emailInput.classList.remove(styles.error)
                emailInput.nextElementSibling.classList.remove(styles.show)
            })
            .catch((err) => {
                negFeedback.classList.add(styles.show);
                window.scrollTo({
                    top: negFeedback.offsetTop - 300,
                    behavior: 'smooth'
                });
                document.querySelector("form").reset();
                setTimeout(() => {
                    negFeedback.classList.remove(styles.show);
                }, 4000);
            });
        } else{
            fieldsFeedback.classList.add(styles.show);

            window.scrollTo({
                top: document.querySelector('form').offsetTop - 200,
                behavior: 'smooth'
            });

            if(!nameInput.value){
                nameInput.classList.add(styles.error)
                nameInput.nextElementSibling.classList.add(styles.show)
            }
            if(!companyInput.value){
                companyInput.classList.add(styles.error)
                companyInput.nextElementSibling.classList.add(styles.show)
            }
            if(!emailInput.value){
                emailInput.classList.add(styles.error)
                emailInput.nextElementSibling.classList.add(styles.show)
            }
        }
    }

    return(
        <React.Fragment>
            {
                supportReady ?
                (
                    <React.Fragment>
                        <Above location="Suporte" />
                        <div className={styles.formHolder}>
                            <form>
                                <div className={styles.formGroup}>
                                    <label>Qual é o teu nome?</label>
                                    <div>
                                        <input name="name" type="text"/>
                                        <span>Campo obrigatório</span>
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Qual é a tua empresa?</label>
                                    <div>
                                        <input name="company" type="text" />
                                        <span>Campo obrigatório</span>
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Como podemos contactar-te?</label>
                                    <div>
                                        <input name="email" type="email" placeholder="E-mail" />
                                        <span>Campo obrigatório</span>
                                        <input name="tel" type="tel" placeholder="Telemóvel" />
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Qual é a tua função? <span>(opcional)</span></label>
                                    <div>
                                        <input name="role" type="text" />
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Onde fica a tua empresa? <span>(opcional)</span></label>
                                    <div>
                                        <input name="local" type="text"/>
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Qual o vosso sector de actividade? <span>(opcional)</span></label>
                                    <div>
                                        <div><input name="activitySector" type="radio" value="Industria" />Industria<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Retalho" />Retalho<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Transportes" />Transportes<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Instalações" />Instalações<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Auditorias" />Auditorias<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Hotelaria" />Hotelaria<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Construção" />Construção<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Manutenção" />Manutenção<span className={styles.checkmark}></span></div>
                                        <div><input name="activitySector" type="radio" value="Outro" />Outro<span className={styles.checkmark}></span></div>
                                        <div>Outro: <input type="text" name="other" /></div>
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Número de colaboradores da tua empresa? <span>(opcional)</span></label>
                                    <div>
                                        <div><input name="size" type="radio" value="5" />Até 5 colaboradores<span className={styles.checkmark}></span></div>
                                        <div><input name="size" type="radio" value="15" />Até 15 colaboradores<span className={styles.checkmark}></span></div>
                                        <div><input name="size" type="radio" value="50" />Até 50 colaboradores<span className={styles.checkmark}></span></div>
                                        <div><input name="size" type="radio" value="100" />Até 100 colaboradores<span className={styles.checkmark}></span></div>
                                        <div><input name="size" type="radio" value="100+" />+ de 100 colaboradores<span className={styles.checkmark}></span></div>
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Observações <span>(opcional)</span></label>
                                    <div>
                                        <textarea rows={5} name="obs"></textarea>
                                    </div>
                                </div>
                                <div>
                                    <input className={styles.button} type="button" value="Submeter" onClick={handleSubmit} />
                                    <p className={styles.positiveFeedback}>Obrigado pelo preenchimento do formulário. Seremos breves a entrar em contacto contigo.</p>
                                    <p className={styles.negativeFeedback}>Aconteceu um erro. Por favor, tenta submeter novamente.</p>
                                    <p className={styles.fieldsFeedback}>Alguns dos campos não foram preenchidos corretamente.</p>
                                </div>
                            </form>
                        </div>
                        <div className={styles.support}>
                            <div>
                                <h2>{supportReady.titulo_suporte_comercial[0].text}</h2>
                                <RichText render={supportReady.descricao_suporte_comercial} />
                                <div>
                                    {
                                        supportReady.contacto_suporte_comercial[0].text.substring(0, 1) === '2' ?
                                        <Phone /> : <Mobile />
                                    }
                                    <Button link={`tel:${supportReady.contacto_suporte_comercial[0].text}`} text={supportReady.contacto_suporte_comercial[0].text} out />
                                </div>
                            </div>
                            <div>
                                <h2>{supportReady.titulo_suporte_tecnico[0].text}</h2>
                                <RichText render={supportReady.descricao_suporte_tecnico} />
                                <div>
                                    {
                                        supportReady.contacto_suporte_tecnico[0].text.substring(0, 1) === '2' ?
                                        <Phone /> : <Mobile />
                                    }
                                    <Button link={`tel:${supportReady.contacto_suporte_tecnico[0].text}`} text={supportReady.contacto_suporte_tecnico[0].text} out />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    );
}

export default Support;