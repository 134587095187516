import React from 'react';
import {RichText} from 'prismic-reactjs'

import styles from './Work.module.scss';

const Work = (props) => {
    console.log(props)
    return (
        <div className={styles.work}>
            <div>
                <h5>{props.subtitle}</h5>
                <h4>{props.title}</h4>
                <RichText render={props.description} />
                {
                    props.items[0].topico.length ?
                    <ul>
                        {props.items.map((item) => <li>{item.topico[0].text}</li>)}
                    </ul>
                    : null
                }
            </div>
            <div>
                <img src={props.image.url} alt={props.image.alt} />
            </div>
        </div>
    );
}

export default Work;