import React from 'react';

import styles from './Opportunity.module.scss';

const Opportunity = (props) => {
    return <div className={styles.opportunity} style={{backgroundImage: `url(${props.image})`}}>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
    </div>
}

export default Opportunity;