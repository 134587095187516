import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom';
import { client } from '../../../utils/prismicHelpers';
import { gsap } from "gsap";
import styles from './Header.module.scss';

import {ReactComponent as Plus} from '../../../assets/svg/plus.svg';

const Header = () => {
    const [prismicData, setPrismicData] = useState({header: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const header = await client.query([
                    Prismic.Predicates.at('document.type', 'menu')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({header: header.results[0].data});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();

        window.addEventListener('scroll', (e) => {
            let scroll = window.innerWidth > 480 ? 600 : 200;
            if(window.scrollY > scroll){
                gsap.to('header', {
                    scrollTrigger: {
                        trigger: 'body #root > section:nth-child(3)',
                        start: "top bottom"
                    },
                    duration: 0.2,
                    backgroundColor: '#0156FF'
                })
            } else{
                gsap.to('header', {
                    duration: 0.2,
                    backgroundColor: 'transparent'
                })
            }
        })
    }, []);

    const headerReady = prismicData.header;

    const toggleMenu = (e) => {
        document.querySelector('nav').classList.toggle(styles.open)
        document.querySelector('header').classList.toggle(styles.open)
        let el = e.target.closest(`.${styles.menuButton}`);
        el.classList.toggle(styles.open)
        el.firstChild.innerText = el.firstChild.innerText === 'MENU' ? 'FECHAR' : 'MENU';
    }

    const closeMenu = (e) => {
        document.querySelector('nav').classList.remove(styles.open)
        document.querySelector('header').classList.remove(styles.open)
        let el = document.querySelector(`.${styles.menuButton}`);
        el.classList.remove(styles.open)
        el.firstChild.innerText = 'MENU';
    }

    return(
        <React.Fragment>
            {
                headerReady ?
                (
                    <header>
                        <Link to="/" onClick={closeMenu}><img src={headerReady.logo.url} alt={headerReady.logo.alt} /></Link>
                        <div className={styles.menuButton} onClick={toggleMenu}><span>Menu</span><Plus /></div>
                        <nav>
                            <div className={styles.visual}>
                                {/* <img src={headerReady.imagem_menu.url} alt={headerReady.imagem_menu.alt}  /> */}
                                <p>{headerReady.fale_connosco[0].text}</p>
                                <a href={`mailto:${headerReady.email[0].text}`}>{headerReady.email[0].text}</a>
                            </div>
                            <div className={styles.text}>
                                {
                                    headerReady.opcoes_menu.map((option) => <Link to={option.url.url.replace('https://', '')} onClick={closeMenu}>{option.texto[0].text}</Link>)
                                }
                            </div>
                        </nav>
                    </header>
                ) : null
            }
        </React.Fragment>
    );
}

export default Header;