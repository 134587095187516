import React from 'react';
import {RichText} from 'prismic-reactjs'

import styles from './SingleFaq.module.scss';

import {ReactComponent as Plus} from '../../../assets/svg/plusFAQs.svg';

const SingleFaq = ({question, answer}) => {
    const toggleFAQ = (e) =>{
        const el = e.target.closest(`.${styles.questionHolder}`);
        el.classList.toggle(styles.open);
        el.nextSibling.classList.toggle(styles.open);

    }

    return (
        <div className={styles.singleFaq}>
            <div className={styles.questionHolder} onClick={toggleFAQ}>
                <p className={styles.question}>{question}</p>
                <Plus />
            </div>
            <div className={styles.answerHolder}>
                <RichText render={answer} />
            </div>
        </div>
    );
}

export default SingleFaq;