import React from 'react';

import styles from './Advantage.module.scss';

const Advantage = (props) => {
    return (
        <div className={styles.advantage}>
            <img src={props.image.url} alt={props.image.alt} />
            <h5>{props.title}</h5>
            <p>{props.description}</p>
        </div>
    );
}

export default Advantage;