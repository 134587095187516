import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import { RichText } from 'prismic-reactjs';
import styles from './Privacy.module.scss';

const Terms = () => {
    const [prismicData, setPrismicData] = useState({privacy: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const privacy = await client.query([
                    Prismic.Predicates.at('document.type', 'termos_de_servico')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({privacy: privacy.results[0].data});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const privacyReady = prismicData.privacy;
                
    return(
        <React.Fragment>
            {
                privacyReady ?
                <div className={styles.privacyHolder}>
                    <h1>{privacyReady.titulo[0].text}</h1>
                    <RichText render={privacyReady.texto} />
                </div>
                : null
            }
        </React.Fragment>
    );
}

export default Terms;