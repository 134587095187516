// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://x-evolution.cdn.prismic.io/api/v2'

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = 'MC5ZSXJZdFJBQUFDUUFQSEFK.77-977-977-9Ee-_ve-_vX8p77-977-9UkltXe-_ve-_vXML77-9a2jvv71dA--_vR9y77-977-977-977-977-9'

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  return '/'
}