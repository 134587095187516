import React from 'react';
import {RichText} from 'prismic-reactjs'

import styles from './Aplicability.module.scss';

import {ReactComponent as Plus} from '../../../assets/svg/plusFAQs.svg';

const Aplicability = ({question, answer}) => {
    const toggleAplicability = (e) =>{
        const el = e.target.closest(`.${styles.questionHolder}`);
        el.classList.toggle(styles.open);
        el.nextSibling.classList.toggle(styles.open);

    }

    return (
        <div className={styles.aplicability}>
            <div className={styles.questionHolder} onClick={toggleAplicability}>
                <p className={styles.question}>{question}</p>
                <Plus />
            </div>
            <div className={styles.answerHolder}>
                {
                    answer.map((topic) => {
                        return <div className={styles.answerTopic}>
                            <h5>{topic.titulo_topico[0].text}</h5>
                            <RichText render={topic.descricao_topico} />
                        </div>
                    })
                }
            </div>
        </div>
    );
}

export default Aplicability;