import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import { gsap, Cubic } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from './About.module.scss'

import Above from '../../components/UI/Above/Above';
import Button from '../../components/UI/Button/Button';
import Opportunity from '../../components/UI/Opportunity/Opportunity';
import Marquee from '../../components/UI/Marquee/Marquee';

import { ReactComponent as Next } from '../../assets/svg/next.svg';
import { RichText } from 'prismic-reactjs';

import folha1 from '../../assets/img/folha1.png';
import folha2 from '../../assets/img/folha2.png';
import folha3 from '../../assets/img/folha3.png';
import folha4 from '../../assets/img/folha4.png';
import folha5 from '../../assets/img/folha5.png';

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const [prismicData, setPrismicData] = useState({about: null, opportunities: null, advantages: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const opportunities = await client.query([
                    Prismic.Predicates.at('document.type', 'oportunidades')
                ], 
                { orderings : '[document.first_publication_date]' });

                const advantages = await client.query([
                    Prismic.Predicates.at('document.type', 'vantagens')
                ], 
                { orderings : '[document.first_publication_date]' });

                const about = await client.query([
                    Prismic.Predicates.at('document.type', 'sobre')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({opportunities: opportunities.results, about: about.results[0].data, advantages: advantages.results});
                setTimeout(() => {
                    if(window.innerWidth > 480){
                        setupGSAP();
                    }
                    document.querySelectorAll(`.${styles.opportunitiesHolder} > div`)[0].classList.add(styles.active)
                }, 600)

                gsap.to( `.${styles.sheetsImageHolder}`,
                    {
                        duration: .3,
                        opacity: 1,
                        autoAlpha: 1
                    }
                );

                setTimeout(() => {
                    const allImg = document.querySelectorAll(`.${styles.sheetsImageHolder} img`);
                    allImg.forEach((el) => {
                        gsap.to(el, {
                            duration: .8,
                            x: 150,
                            stagger: .15,
                            scrollTrigger: {
                                trigger: el,
                                start: 'top-=175 top',
                                scrub: true
                            }
                        });
                    })
                }, 300);
                
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const lastWord = (text) => {
        var splitText = text.trim().split(" ");
        var last = splitText.pop();
        return splitText.join(" ") + (splitText.length > 0 ? " <span>" + last + "</span>" : last);
    };

    let indexSelected = 0;
    let indexSelectedOpportunities = 0;

    const moveBack = (e) =>{
        let els = document.querySelectorAll(`.${styles.opportunitiesHolder} > div`);
        els[indexSelected].classList.remove(styles.active);
        indexSelected--;
        els[indexSelected].classList.add(styles.active);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected) * 100}% - ${(indexSelected) * 1.9}rem))` : `translate(calc(-${(indexSelected) * 71}rem))`;
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = 'translate(0)'
            }
        });
        if(indexSelected === 0) document.querySelector(`.${styles.prev}`).classList.remove(styles.show)
        document.querySelector(`.${styles.next}`).classList.remove(styles.hide)
    }

    const moveForward = (e) =>{
        let els = document.querySelectorAll(`.${styles.opportunitiesHolder} > div`);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected + 1) * 100}% - ${(indexSelected + 1) * 1.9}rem))` : `translate(calc(-${(indexSelected + 1) * 71}rem))`;
        let rem2 = window.innerWidth <= 480 ? 'translate(calc(-100% - 1.9rem))' : 'translate(-71rem)';
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = rem2
            }
        });
        els[indexSelected].classList.remove(styles.active);
        indexSelected++;
        els[indexSelected].classList.add(styles.active);
        if(indexSelected + 1 === els.length) document.querySelector(`.${styles.next}`).classList.add(styles.hide)
        document.querySelector(`.${styles.prev}`).classList.add(styles.show)
    }

    const moveBackOpportunities = (e) =>{
        let els = document.querySelectorAll(`.${styles.advantagesHolder} > div`);
        els[indexSelectedOpportunities].classList.remove(styles.active);
        indexSelectedOpportunities--;
        els[indexSelectedOpportunities].classList.add(styles.active);
        els.forEach(el => {
            if(indexSelectedOpportunities > 0){
                el.style.transform = `translate(calc(-${(indexSelectedOpportunities) * 64}rem))`
            } else{
                el.style.transform = 'translate(0)'
            }
        });
        if(indexSelectedOpportunities === 0) document.querySelector(`.${styles.prevO}`).classList.remove(styles.show)
        document.querySelector(`.${styles.nextO}`).classList.remove(styles.hide)
    }

    const moveForwardOpportunities = (e) =>{
        let els = document.querySelectorAll(`.${styles.advantagesHolder} > div`);
        els.forEach(el => {
            if(indexSelectedOpportunities > 0){
                el.style.transform = `translate(calc(-${(indexSelectedOpportunities + 1) * 64}rem))`
            } else{
                el.style.transform = 'translate(-64rem)'
            }
        });
        els[indexSelectedOpportunities].classList.remove(styles.active);
        indexSelectedOpportunities++;
        els[indexSelected].classList.add(styles.active);
        if(indexSelectedOpportunities + 1 === els.length) document.querySelector(`.${styles.nextO}`).classList.add(styles.hide)
        document.querySelector(`.${styles.prevO}`).classList.add(styles.show)
    }

    const setupGSAP = () => {
        const ST = ScrollTrigger.create({
            trigger: `.${styles.solutions}`,
            start: "top-=50 top",
            end: "bottom bottom",
            onUpdate: getCurrentSection,
            pin: `.${styles.contentImages}`,
            onLeave: function () {
                let el = document.querySelector(`.${styles.contentText}`);
                if(el !== null) el.classList.remove(styles.fix);
            },
            onEnter: function(){
                let el = document.querySelector(`.${styles.contentText}`);
                if(el !== null) el.classList.add(styles.fix);
            },
            onLeaveBack: function () {
                let el = document.querySelector(`.${styles.contentText}`);
                if(el !== null) el.classList.remove(styles.fix);
            },
            onEnterBack: function(){
                let el = document.querySelector(`.${styles.contentText}`);
                if(el !== null) el.classList.add(styles.fix);
            }
        });

        const contentMarkers = gsap.utils.toArray(`.${styles.contentMarker}`);

        contentMarkers.forEach((marker) => {
            marker.content = document.querySelector(`#${marker.dataset.markerContent}`);
          
            if (marker.content.tagName === "IMG") {
              gsap.set(marker.content, { transformOrigin: "top", opacity: 0 });
          
              marker.content.enter = function () {
                gsap.to(
                    marker,
                    {fontSize: '4.4rem', duration: 1}
                )
                gsap.fromTo(
                  marker.content,
                  { autoAlpha: 0, opacity: 0 },
                  { delay: .2, duration: .6, autoAlpha: 1, opacity: 1 },
                );
              };
            }
          
            marker.content.leave = function () {
                gsap.to(
                    marker,
                    {fontSize: '3.2rem', duration: 1}
                )
              gsap.to(marker.content, { duration: .8, autoAlpha: 0, opacity: 0 });
            };
        });

        let lastContent;
        function getCurrentSection() {
            let newContent;
            const currScroll = window.scrollY;

            // Find the current section
            contentMarkers.forEach((marker) => {
                if (currScroll > marker.offsetTop + 450) {
                    newContent = marker.content;
                }
            });

            if (
                newContent &&
                (lastContent == null || !newContent.isSameNode(lastContent))
            ) {
                if (lastContent) {
                    lastContent.leave();
                }
                newContent.enter();
                lastContent = newContent;
            }
        }

        ST.enable();
    }

    const aboutReady = prismicData.about;
    const opportunitiesReady = prismicData.opportunities;

    return(
        <React.Fragment>
            {
                aboutReady ?
                (
                    <React.Fragment>
                        <Above location="Sobre" />
                        <section className={styles.solutions}>
                            <div className={styles.contentText}>
                                <h3>{aboutReady.titulo_solucao[0].text}</h3>
                                <div className={styles.topics}>
                                    {
                                        aboutReady.topicos.map((topic, index) => <h4 data-marker-content={`img${index}`} className={styles.contentMarker}>{topic.topico[0].text}</h4>)
                                    }
                                </div>
                            </div>
                            <div className={styles.contentImages}>
                                {
                                    aboutReady.topicos.map((topic, index) => <img id={`img${index}`} src={topic.imagem.url} alt={topic.imagem.alt}/>)
                                }
                            </div>
                        </section>
                        <Marquee blue={aboutReady.marquee_azul[0].text} dark={aboutReady.marquee[0].text} />
                        <section className={styles.opportunities}>
                            <h3>{aboutReady.subtitulo_oportunidades[0].text}</h3>
                            <RichText render={aboutReady.titulo_oportunidades} />
                            <div className={styles.opportunitiesHolder}>
                                {
                                    opportunitiesReady ?
                                    opportunitiesReady.map(({data}, index) => <Opportunity title={data.titulo[0].text} description={data.descricao[0].text} image={data.imagem_fundo.url} />)
                                    : null
                                }
                            </div>
                            <div className={styles.prev} onClick={moveBack}><Next /></div>
                            <div className={styles.next} onClick={moveForward}><Next /></div>
                        </section>
                        <section className={styles.advantages}>
                            <h3>{aboutReady.subtitulo_vantagens[0].text}</h3>
                            <h2>{aboutReady.titulo_vantagens[0].text}</h2>
                            <div className={styles.advantagesHolder}>
                                {
                                    aboutReady.calculo.map((single) => {
                                        return <div className={styles.advantage}>
                                            <div><p className={styles.value}>{single.operador[0].text}</p></div>
                                            <div>
                                                <p className={styles.value}>{single.valor[0].text}</p>
                                                <p>{single.descricao[0].text}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </section>
                        <video width="720" height="405" controls poster={aboutReady.poster_video.url}>
                            <source src={aboutReady.video.url} type="video/mp4" />
                            Este browser não suporta o vídeo
                        </video>
                        <section className={styles.form}>
                            {/* <div className={styles.sheetsImageHolder}>
                                <img src={folha1} alt="folha 1" />
                                <img src={folha2} alt="folha 2" />
                                <img src={folha3} alt="folha 3" />
                                <img src={folha4} alt="folha 4" />
                                <img src={folha5} alt="folha 5" />
                            </div> */}
                            <h4 dangerouslySetInnerHTML={{__html: lastWord(aboutReady.titulo_formulario[0].text)}}></h4>
                            <Button text="Preencha este formulário" special link='/contactos' />
                        </section>
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    );
}

export default About;