import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom';
import { client } from '../../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs'
import { send } from 'emailjs-com';

import styles from './Footer.module.scss';

const Footer = () => {
    const [prismicData, setPrismicData] = useState({footer: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const footer = await client.query([
                    Prismic.Predicates.at('document.type', 'rodape')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({footer: footer.results[0].data});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();

        const emailInput = document.querySelector('form#newsletter input[name="email"]');

        if(emailInput.value){
            send(
                'service_53bi4co',
                'template_834muao',
                {
                    email: emailInput.value ? emailInput.value : '',
                    subject: 'Nova Subscrição Newsletter'
                },
                'user_j3T9DNb1qMwcLLoqfi1BC'
            )
            .then((response) => {
                emailInput.classList.add(styles.small);
                emailInput.value = 'Submissão da newsletter efetuada';
                setTimeout(() => {
                    emailInput.value = '';
                    emailInput.classList.remove(styles.small);
                    document.querySelector("form#newsletter").reset();
                }, 3000);
            })
            .catch((err) => {
                emailInput.classList.add(styles.small);
                emailInput.value = 'Ocorreu um erro. Tente mais tarde.';
                setTimeout(() => {
                    emailInput.classList.remove(styles.small);
                    emailInput.value = '';
                    document.querySelector("form#newsletter").reset();
                }, 3000);
            });
        }
    }

    const footerReady = prismicData.footer;

    return(
        <React.Fragment>
            {
                footerReady ?
                (
                    <footer>
                        <div>
                            <img src={footerReady.logo.url} alt={footerReady.logo.alt} />
                        </div>
                        <div className={styles.footerInfo}>
                            <div>
                                <h4>{footerReady.titulo_ajuda[0].text}</h4>
                                <Link to="/faqs">FAQs</Link>
                                <Link to="/contactos">Contactos</Link>
                            </div>
                            <div>
                                <h4>{footerReady.titulo_contacto[0].text}</h4>
                                <a href={`mailto:${footerReady.email[0].text}`} target="_blank" rel="noreferrer">{footerReady.email[0].text}</a>
                                <a href={`tel:${footerReady.numero_de_telemovel[0].text}`} target="_blank" rel="noreferrer">{footerReady.numero_de_telemovel[0].text}</a>
                                <a href={`tel:${footerReady.numero_de_telefone[0].text}`} target="_blank" rel="noreferrer">{footerReady.numero_de_telefone[0].text}</a>
                            </div>
                            <div>
                                <h4>{footerReady.titulo_redes_sociais[0].text}</h4>
                                {
                                    footerReady.links_redes_sociais.map((link) => <a href={link.link_rede_social.url ? link.link_rede_social.url : null} target="_blank" rel="noreferrer">{link.rede_social}</a>)
                                }
                            </div>
                            <div className={styles.newsletter}>
                                <h4>{footerReady.titulo_newsletter[0].text}</h4>
                                <p>{footerReady.descricao_newsletter[0].text}</p>
                                <form id="newsletter">
                                    <input type="email" name="email" placeholder="Insira o seu e-mail" />
                                    <input type="button" name="submit" value="Subscrever" onClick={handleSubmit} />
                                </form>
                                <p className={styles.rgpd}>{footerReady.rgpd_newsletter[0].text}</p>
                            </div>
                        </div>
                        <div className={styles.notes}>
                            <Link to="/politica-privacidade">Politica de privacidade</Link>
                            <Link to="/termos-servico">Termos de serviço</Link>
                        </div>
                    </footer>
                ) : null
            }
        </React.Fragment>
    );
}

export default Footer;